import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'

import { Form, TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import humanizeDuration from 'humanize-duration'
import ReactTooltip from 'react-tooltip'
import { mq } from './../../../utility/breakpoint'

import { log } from 'console'
import * as timezonePlugin from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import i18n from 'i18next'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorBar, TooltipIcon } from '../../atoms'
import { RoleSelectData } from '../appointmentForm'
import { CustomElem, FormRepeat } from './'
import {
  BueClock,
  BueClose,
  Button,
  Heading,
  Input,
  Paragraph,
  Radiobutton,
  RadiobuttonGroup,
  Select,
  Textarea,
} from './../../'
import { SalesUser } from './../../../api'
import { IanaTimezones } from './../../../utility'
import { DatePicker, DateRangePicker } from './../../molecules/datePicker'
import { validHostsEmailAddresses } from './allowedMails'
import { CountryList } from './countryList'
import { MeetingLanguages } from './meetingLanguages'
dayjs.extend(utc)
dayjs.extend(timezonePlugin)

export const SALESFORM_SENT = 'sent'
export const SALESFORM_ERROR = 'error'

export interface SalesFormProps {
  onSubmit?(data: SalesFormData): void
}

export interface SalesFormData {
  version: string
  event: string
  operatingCompany: string
  country: string
  role: string
  meetinglanguage: string
  emaillanguage: string
  note: string
  date: string
  start: string
  end: string
  time: string
  timezone: string
  email: string
  gender: string
  firstName: string
  lastName: string
  expertEmail: string
  expertFirstname: string
  expertLastname: string
  expertStart: string
  expertEnd: string
  host: SalesUser
  hostEmail: string
  hostFirstname: string
  hostLastname: string
  resources: SalesUser[]
}

export interface SalesFormProps {
  onSubmit?(data: SalesFormData): void
  onComplete?(success: boolean, error?: string): void
  onReset?: Function
  status?: typeof SALESFORM_SENT | typeof SALESFORM_ERROR | undefined
  error?: string
}

export const SalesForm: React.FC<SalesFormProps> = props => {
  const { register, trigger, handleSubmit, errors, getValues } = useForm<
    SalesFormData
  >({ mode: 'onSubmit' })

  const [selectedTimezone, setSelectedTimezone] = React.useState<string>(
    TimeUtil.getUsersTimezone() || 'Europe/London'
  )

  const [formSubmitted, setFormSubmitted] = React.useState(false)

  const { t } = useTranslation('salesform')

  const [sending, setSending] = React.useState(false)
  const [clients, setClients] = React.useState([''])

  const MIN_DATE = TimeUtil.getUtc('2021-06-21 00:00:00')
  const MAX_DATE = TimeUtil.getUtc('2021-06-25 00:00:00')

  const START_TIME = TimeUtil.getUtc('2021-06-21 10:00:00')
  const END_TIME = TimeUtil.getUtc('2021-06-21 12:00:00')

  const DATE_RANGES = [
    [
      TimeUtil.getUtc('2021-09-01 00:00:00'),
      TimeUtil.getUtc('2021-10-12 23:59:59'),
    ],
    /*,
    [
      TimeUtil.getUtc('2021-07-03 00:00:00'),
      TimeUtil.getUtc('2021-07-07 23:59:59'),
    ],
    [
      TimeUtil.getUtc('2021-07-10 00:00:00'),
      TimeUtil.getUtc('2021-07-14 23:59:59'),
    ], */
    ,
  ]

  const [experts, setExperts] = React.useState([])

  const [minDate, setMinDate] = React.useState(MIN_DATE)
  const [maxDate, setMaxDate] = React.useState(MAX_DATE)

  const [dateRanges, setDateRanges] = React.useState(DATE_RANGES)

  const flattenDateRange = dRanges => {
    const dateAdd = []
    let fPossibleDate: dayjs.Dayjs | null = null
    dRanges.forEach(([start, end]) => {
      let cd: dayjs.Dayjs = start
      while (cd.isBefore(end)) {
        if (cd.isSameOrAfter(TimeUtil.getNowUtc().add(1, 'day'), 'day')) {
          if (!fPossibleDate) {
            fPossibleDate = cd
          }
          dateAdd.push(cd)
        }
        cd = cd.add(1, 'day')
      }
    })

    return { dates: dateAdd, firstPossibleDate: fPossibleDate }
  }

  const firstPossibleDate = flattenDateRange(DATE_RANGES).firstPossibleDate
  const [dateFlatRanges, setDateFlatRange] = React.useState(
    flattenDateRange(DATE_RANGES).dates
  )
  const [date, setDate] = React.useState(firstPossibleDate)

  // GET FIRST POSSIBLE HOUR
  const currentTimeAdded = TimeUtil.get(
    new Date(
      Math.ceil(
        TimeUtil.getNow().add(1, 'day').add(20, 'minutes').toDate().getTime() /
          900000
      ) * 900000
    )
  )

  const firstPossibleTime = date.isSame(TimeUtil.getNow().add(1, 'day'), 'day')
    ? currentTimeAdded
    : firstPossibleDate.hour(0).minute(0)

  const firstPossibleTimeStart = firstPossibleTime.isBefore(
    firstPossibleTime.hour(12).minute(0).second(0)
  )
    ? firstPossibleTime.hour(12).minute(0).second(0)
    : firstPossibleTime

  const [startDate, setStartDate] = React.useState(firstPossibleTimeStart)

  const [endDate, setEndDate] = React.useState(
    firstPossibleTimeStart.add(2, 'hours')
  )

  const [startDateHosts, setStartDateHosts] = React.useState<dayjs.Dayjs[]>([
    firstPossibleTimeStart,
  ])
  const [endDateHosts, setEndDateHosts] = React.useState<dayjs.Dayjs[]>([
    firstPossibleTimeStart.add(1, 'day'),
  ])

  const [dateTimeRange, setDateTimeRange] = React.useState([
    firstPossibleTime,
    firstPossibleTime.hour(23).minute(59),
  ])

  const [duration, setDuration] = React.useState(0)

  const changeDate = (d: dayjs.Dayjs) => {
    // UPDATE TIMES
    const nStartDate = startDate.set('date', d.date()).set('month', d.month())
    const nEndDate = endDate.set('date', d.date()).set('month', d.month())

    setStartDate(nStartDate)
    setEndDate(nEndDate)
    // GET FIRST POSSIBLE HOUR
    const cTimeAdded = TimeUtil.get(
      new Date(
        Math.ceil(
          TimeUtil.getNow().add(20, 'minutes').toDate().getTime() / 900000
        ) * 900000
      )
    )

    const fPossibleTime = nStartDate
      .set('date', d.date() + 1)
      .isSame(TimeUtil.getNow(), 'day')
      ? cTimeAdded
      : nStartDate.hour(0).minute(0)

    setDateTimeRange([fPossibleTime, fPossibleTime.hour(23).minute(59)])
  }

  const changeTime = (time: dayjs.Dayjs[], index: number | undefined) => {
    if (index === undefined) {
      setStartDate(time[0])
      setEndDate(time[1])
    } else {
      const sd = startDateHosts

      sd[index] = time[0]
      setStartDateHosts(sd)

      const ed = endDateHosts
      ed[index] = time[1]
      setEndDateHosts(ed)
    }

    // SET DATE FROM START TIME
    /*
    const tuStartDate = new Date(date.toDate())
    tuStartDate.setHours(time[0].hour())
    tuStartDate.setMinutes(time[0].minute())
    tuStartDate.setSeconds(0) */

    /*     console.log("tuStartDate.toString()", tuStartDate.toString())
    setStartDate(TimeUtil.get(tuStartDate.toString())) */

    /*  const tuEndDate = new Date(date.toDate())
    tuEndDate.setHours(time[1].hour())
    tuEndDate.setMinutes(time[1].minute())
    tuEndDate.setSeconds(0)
    setEndDate(TimeUtil.getUtc(tuEndDate.toString())) */
  }

  const timezoneSelectData = () =>
    IanaTimezones.map(({ name }, index) => (
      <option value={name} key={index}>
        {name}
      </option>
    ))

  const meetingLanguageSelectData = () => (
    <>
      {MeetingLanguages.map(({ name, key }, index) => (
        <option value={key} key={index}>
          {name[i18n.language]}
        </option>
      ))}
    </>
  )

  const emilLanguageSelectData = () => (
    <>
      <option />
      <option value="en">English</option>
      <option value="de">Deutsch</option>
    </>
  )

  const eventsData = () => (
    <>
      <option />
      <option value="1">Event 1</option>
      <option value="2">Event 2</option>
    </>
  )

  const operationCompanyData = () => (
    <>
      <option value="SI">SI</option>
      <option value="Training">
        {i18n.language === 'de'
          ? `Testtermin für Training`
          : `Testappointment for training`}
      </option>
    </>
  )

  /*   const countrySelectData = () => (
    <>
      <option />
      {CountryList.map(({ value, name, code }, index) => (
        <option value={value} key={index}>
          {name[i18n.language]}
        </option>
      ))}
    </>
  )
  const options = [
    { name: 'Swedish', value: 'sv' },
    { name: 'English', value: 'en' },
  ] */

  const countrySelectData = () =>
    [
      {
        name: { en: '', de: '' },
        code: '',
        value: 'string',
      },
      ...CountryList,
    ]
      .sort((a, b) =>
        a.name[i18n.language].localeCompare(b.name[i18n.language])
      )
      .map(({ value, name, code }) => ({
        name: name[i18n.language],
        value,
      }))

  const usePrevious = val => {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = val
    })

    return ref.current
  }
  const prevTimezone = usePrevious(selectedTimezone) || selectedTimezone

  const [cDate, setCDate] = React.useState<dayjs.Dayjs>(dayjs())
  const [cutcDate, setCutcCDate] = React.useState<dayjs.Dayjs>(dayjs())

  const shiftTimezone = (
    shiftDate: dayjs.Dayjs,
    timezone: string,
    pTimezone = 'Europe/London'
  ): dayjs.Dayjs => {
    return shiftDate.add(
      TimeUtil.getUtcOffsetForTz(pTimezone) * -1 +
        TimeUtil.getUtcOffsetForTz(timezone),
      'minutes'
    )
  }

  const shiftTimezoneSend = (
    shiftDate: dayjs.Dayjs,
    timezone: string,
    pTimezone = 'Europe/London'
  ): dayjs.Dayjs =>
    dayjs.tz(shiftDate.format('YYYY-MM-DD HH:mm'), pTimezone).utc()

  React.useEffect(() => {
    console.log(
      'selectedTimezone',
      prevTimezone,
      selectedTimezone,
      startDate.format()
    )
    const shiftedStartTime: dayjs.Dayjs = shiftTimezone(
      startDate,
      selectedTimezone,
      prevTimezone
    )
    const shiftedEndTime: dayjs.Dayjs = shiftTimezone(
      endDate,
      selectedTimezone,
      prevTimezone
    )

    const _date = dayjs(
      new Date(
        shiftedStartTime.year(),
        shiftedStartTime.month(),
        shiftedStartTime.date()
      )
    )
    setDate(_date)

    const shifteddMinDate: dayjs.Dayjs = shiftTimezone(
      MIN_DATE.utc(),
      selectedTimezone
    )
    setMinDate(shifteddMinDate)

    const shifteddMaxDate: dayjs.Dayjs = shiftTimezone(
      MAX_DATE.utc(),
      selectedTimezone
    )
    setMaxDate(shifteddMaxDate)

    setStartDate(shiftedStartTime)
    setEndDate(shiftedEndTime)
    validateTimes([shiftedStartTime, shiftedEndTime])
  }, [selectedTimezone])

  const isValidHostsEmailAddress = (email: string) => {
    const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    if (!regexp.test(email)) {
      return false
    }

    return validHostsEmailAddresses.some(emailAddress =>
      (email || '').endsWith(emailAddress)
    )
  }

  const validateTimes = (times: dayjs.Dayjs[]) => {
    /*setStartDate(shiftedStartTime)
    setEndDate(shiftedEndTime)*/
  }
  const shiftSubmit = (d: string) => {
    if (d === '') {
      return null
    }

    //return dayjs(TimeUtil.getUtc(d).format('YYYY-MM-DD HH:mm')).utc().format()
    return dayjs.tz(d.slice(0, 19), selectedTimezone).utc().format()

    return TimeUtil.getUtc(d.slice(0, 19)).utc().format() // REMOVE TIMEZONE

    return shiftTimezoneSend(
      TimeUtil.getUtc(d),
      'Europe/London',
      selectedTimezone
    ).format()
  }

  const shiftSubmitTimes = data => {
    const shiftedData = { ...data }
    shiftedData.start = shiftSubmit(shiftedData.start)
    shiftedData.end = shiftSubmit(shiftedData.end)

    shiftedData.expertStart =
      shiftedData.expertStart?.map(expertStart => shiftSubmit(expertStart)) ||
      []
    shiftedData.expertEnd =
      shiftedData.expertEnd?.map(expertEnd => shiftSubmit(expertEnd)) || []

    return shiftedData
  }

  return (
    <SalesFormOuter>
      <Form
        onSubmit={handleSubmit(data => {
          const sendData = shiftSubmitTimes(data)
          console.log('sendData', sendData)
          setSending(true)
          props.onSubmit && props.onSubmit(sendData)
        })}
      >
        {!props.status && (
          <>
            <Heading type="h2" spacing={{ bottom: 2 }}>
              {t('intro.header')}
            </Heading>
            <Paragraph>{parse(t('intro.copy'))}</Paragraph>
            <input
              type="hidden"
              name="emaillanguage"
              ref={register({ required: true })}
              value={i18n.language}
            />

            <SalesFormContent>
              <FormField>
                <Heading type="h5">{t('category.label')}</Heading>

                <FormRow>
                  {wrapInput(
                    'country',
                    Select,
                    {
                      label: t('fields.country'),
                      placeholder: t('fields.country'),
                      name: 'country',
                      ref: register({ required: true }),
                      options: countrySelectData(),
                      className: '.elem',
                      search: true,
                      trigger: () => trigger('country'),
                      error:
                        errors && errors[`country`]
                          ? t('fields.required.country')
                          : '',
                    },
                    1
                  )}
                  {wrapInput(
                    'meetinglanguage',
                    Select,
                    {
                      label: t('fields.meetinglanguage'),
                      defaultValue: i18n.language.toUpperCase(),
                      ref: register({ required: true }),
                      children: meetingLanguageSelectData(),
                      className: '.elem',
                      error:
                        errors && errors[`meetinglanguage`]
                          ? t('fields.required.meetinglanguage')
                          : '',
                    },
                    1
                  )}
                </FormRow>
              </FormField>

              <FormField>
                <Heading type="h5">{t('appointment.label')}</Heading>

                <FormRow>
                  {wrapInput(
                    'date',
                    DatePicker,
                    {
                      name: 'date',
                      dateRanges: dateFlatRanges,
                      value: date,
                      dateFormat: 'd. MMMM y',
                      showTime: false,
                      filterDate: date => {
                        const day = date.getDay()
                        if (date.getMonth() === 8) {
                          const _date = date.getDate()
                          if (_date === 11 || _date === 12) {
                            return true
                          }
                        }

                        return day !== 0 && day !== 6
                      },
                      ref: register({ required: true }),
                      onChange: e => changeDate(e),
                    },
                    1
                  )}
                  <MobileBreak />

                  {wrapInput(
                    'time',
                    DateRangePicker,
                    {
                      name: ['start', 'end'],
                      value: [startDate, endDate],
                      range: dateTimeRange,
                      register,
                      onChange: (time: dayjs.Dayjs[]) => changeTime(time),
                    },
                    2
                  )}
                  {startDate.isBefore(endDate) && (
                    <div
                      className="elem"
                      style={{
                        flex: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        height: '30px',
                        paddingTop: '20px',
                      }}
                    >
                      {humanizeDuration(endDate.diff(startDate), {
                        language: i18n.language,
                      })}
                    </div>
                  )}
                  {wrapInput(
                    'timezone',
                    Select,
                    {
                      name: 'timezone',
                      ref: register({ required: true }),
                      /*                       placeholder: t('fields.timezone'),
                       */ defaultValue: 'Europe/Berlin',
                      value: selectedTimezone,
                      children: timezoneSelectData(),
                      onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                        setSelectedTimezone(e.currentTarget.value),
                    },
                    1
                  )}
                </FormRow>
              </FormField>
              <FormField>
                {clients.map((val, i) => (
                  <>
                    <HeadingAdditional>
                      {i ? (
                        <>
                          <Heading type="h5">
                            {t('additionalclient.label')}{' '}
                          </Heading>
                          <RemoveBtn
                            onClick={() => {
                              setClients(
                                clients
                                  .slice(0, i)
                                  .concat(clients.slice(i + 1, clients.length))
                              )
                            }}
                          >
                            <BueClose />
                          </RemoveBtn>
                        </>
                      ) : (
                        <Heading type="h5">{t('client.label')} </Heading>
                      )}
                    </HeadingAdditional>

                    <FormRow key={i}>
                      {wrapInput(
                        'gender',
                        RadiobuttonGroup,
                        {
                          children: (
                            <>
                              <Radiobutton
                                value="Mrs."
                                name={`gender[${i}]`}
                                ref={register({ required: true })}
                              >
                                {t('fields.mrs')}
                              </Radiobutton>
                              <Radiobutton
                                value="Mr."
                                name={`gender[${i}]`}
                                ref={register({ required: true })}
                              >
                                {t('fields.mr')}
                              </Radiobutton>
                            </>
                          ),
                          error:
                            errors[`gender`] && errors[`gender`][i]
                              ? t('fields.required.gender')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />{' '}
                      {wrapInput(
                        'email',
                        Input,
                        {
                          placeholder: t('fields.email'),
                          name: `email[${i}]`,
                          ref: register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: t('fields.required'),
                            },
                          }),
                          error:
                            errors[`email`] && errors[`email`][i]
                              ? t('fields.required.email')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />
                      {wrapInput(
                        'firstName',
                        Input,
                        {
                          placeholder: t('fields.firstName'),
                          name: `firstName[${i}]`,
                          ref: register({
                            required: true,
                          }),
                          error:
                            errors[`firstName`] && errors[`firstName`][i]
                              ? t('fields.required.firstName')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />
                      {wrapInput(
                        'lastName',
                        Input,
                        {
                          placeholder: t('fields.lastName'),
                          name: `lastName[${i}]`,
                          ref: register({
                            required: true,
                          }),
                          error:
                            errors[`lastName`] && errors[`lastName`][i]
                              ? t('fields.required.lastName')
                              : '',
                        },
                        1
                      )}
                      <DesktopBreak />
                      {wrapInput(
                        'companyName',
                        Input,
                        {
                          placeholder: t('fields.companyName'),
                          name: `companyName[${i}]`,
                          ref: register(),
                          error:
                            errors[`companyName`] && errors[`companyName`][i]
                              ? t('fields.required')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />{' '}
                      {wrapInput(
                        'role',
                        Select,
                        {
                          placeholder: t('fields.role'),
                          name: `role[${i}]`,
                          ref: register(),
                          children: (
                            <>
                              <option />
                              {RoleSelectData.map(({ name, value }, key) => (
                                <option value={value} key={value}>
                                  {name[i18n.language]}
                                </option>
                              ))}
                            </>
                          ),
                          error:
                            errors[`role`] && errors[`role`][i]
                              ? t('fields.required')
                              : '',
                        },
                        1
                      )}
                      <MobileBreak />
                      {wrapInput(
                        'companyCountry',
                        Select,
                        {
                          placeholder: t('fields.companyCountry'),
                          name: `companyCountry[${i}]`,
                          ref: register(),
                          options: countrySelectData(),
                          className: '.elem',
                          search: true,
                          trigger: () => trigger('country'),
                          error:
                            errors[`companyCountry`] &&
                            errors[`companyCountry`][i]
                              ? t('fields.required')
                              : '',
                        },
                        1
                      )}
                    </FormRow>
                  </>
                ))}
                <Button
                  onClick={() => {
                    setClients([...clients, ''])
                  }}
                >
                  {t('fields.addAdditionalClient')}
                </Button>
              </FormField>
              <FormField>
                <Heading type="h5">{t('host.label')}</Heading>
                <FormRow>
                  {wrapInput(
                    'hostEmail',
                    Input,
                    {
                      name: 'hostEmail',
                      placeholder: t('fields.email'),
                      ref: register({
                        required: true,
                        validate: isValidHostsEmailAddress,
                      }),
                      error: errors['hostEmail']
                        ? t('fields.required.hostEmail')
                        : '',
                    },
                    1
                  )}
                  <MobileBreak />
                  {wrapInput(
                    'hostFirstname',
                    Input,
                    {
                      name: 'hostFirstname',
                      placeholder: t('fields.firstName'),
                      ref: register({
                        required: true,
                      }),
                      error: errors['hostFirstname']
                        ? t('fields.required.firstName')
                        : '',
                    },
                    1
                  )}
                  <MobileBreak />
                  {wrapInput(
                    'hostLastname',
                    Input,
                    {
                      name: 'hostLastname',
                      placeholder: t('fields.lastName'),
                      ref: register({
                        required: true,
                      }),
                      error: errors['hostLastname']
                        ? t('fields.required.lastName')
                        : '',
                    },
                    1
                  )}
                  <MobileBreak />
                </FormRow>
              </FormField>
              <FormField>
                <>
                  {experts.map((val, i) => (
                    <>
                      <HeadingAdditional>
                        <Heading type="h5">{t('extrahost.label')}</Heading>
                        <RemoveBtn
                          onClick={() => {
                            setExperts(
                              experts
                                .slice(0, i)
                                .concat(experts.slice(i + 1, experts.length))
                            )
                          }}
                        >
                          <BueClose />
                        </RemoveBtn>
                      </HeadingAdditional>
                      <FormRow key={i}>
                        {wrapInput(
                          'expertEmail',
                          Input,
                          {
                            name: `expertEmail[${i}]`,
                            placeholder: t('fields.email'),
                            ref: register({
                              required: false,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: '',
                              },
                            }),
                            error:
                              errors[`expertEmail`] && errors[`expertEmail`][i]
                                ? t('fields.required.email')
                                : '',
                          },
                          1
                        )}
                        <MobileBreak />
                        {wrapInput(
                          'expertFirstname',
                          Input,
                          {
                            name: `expertFirstname[${i}]`,
                            placeholder: t('fields.firstName'),
                            ref: register({
                              required:
                                (getValues()?.expertEmail &&
                                  getValues()?.expertEmail[i] !== '') ||
                                false,
                            }),
                            error:
                              errors[`expertFirstname`] &&
                              errors[`expertFirstname`][i]
                                ? t('fields.required.firstName')
                                : '',
                          },
                          1
                        )}
                        <MobileBreak />
                        {wrapInput(
                          'expertLastname',
                          Input,
                          {
                            name: `expertLastname[${i}]`,
                            placeholder: t('fields.lastName'),
                            ref: register({
                              required:
                                (getValues()?.expertEmail &&
                                  getValues()?.expertEmail[i] !== '') ||
                                false,
                            }),
                            error:
                              errors[`expertLastname`] &&
                              errors[`expertLastname`][i]
                                ? t('fields.required.lastName')
                                : '',
                          },
                          1
                        )}
                        <MobileBreak />
                        {wrapInput(
                          'time',
                          DateRangePicker,
                          {
                            name: [`expertStart[${i}]`, `expertEnd[${i}]`],
                            label: [t('fields.start'), t('fields.end')],
                            value: startDateHosts[i]
                              ? [startDateHosts[i], endDateHosts[i]]
                              : [startDate, endDate],
                            range: [startDate, endDate],
                            autoSortTimes: true,
                            toggle: true,
                            isSlave: true,

                            register,
                            onChange: (time: dayjs.Dayjs[]) =>
                              changeTime(time, i),
                          },
                          'initial'
                        )}
                      </FormRow>
                    </>
                  ))}
                  <Button
                    onClick={() => {
                      setExperts([...experts, [startDate, endDate]])
                    }}
                  >
                    {t('fields.addAdditionalHost')}
                  </Button>
                </>
              </FormField>
              <FormField>
                <FormRow>
                  {wrapInput(
                    'note',
                    Textarea,
                    {
                      rows: 4,
                      placeholder: t('fields.note'),
                      ref: register({ required: false }),
                      name: `note`,
                    },
                    1
                  )}
                </FormRow>
              </FormField>
            </SalesFormContent>
            <Button
              type="submit"
              onClick={() => setFormSubmitted(true)}
              /*  disabled={sending || !startDate.isBefore(endDate)} */
            >
              {t('fields.submit')}
            </Button>
          </>
        )}
        {props.status === SALESFORM_SENT && (
          <>
            <Heading type="h2">{t('sent.header')}</Heading>
            <Paragraph>{t('sent.copy')}</Paragraph>
            <Button
              icon="back"
              onClick={() => {
                props.onReset && props.onReset()
                setSending(false)
              }}
            >
              {t('sent.back')}
            </Button>
          </>
        )}

        {props.status === SALESFORM_ERROR && (
          <>
            <Heading type="h2">{t('error.headline')}</Heading>
            <Paragraph>{t('error.description')}</Paragraph>
            <Button
              icon="back"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  document.location.reload()
                }
              }}
            >
              {t('error.retry')}
            </Button>
          </>
        )}
        <ReactTooltip effect="solid" className="customTooltip" />
        <ErrorBar
          visible={Object.keys(errors).length > 0 ? `${Math.random()}` : ''}
        >
          {parse(t('errorbar'))}
        </ErrorBar>
      </Form>
    </SalesFormOuter>
  )
}

const SalesFormOuter = styled.div(
  ({ theme: { colors } }) => `
  margin-top:60px;
  ${mq[3]}{
    margin-top:0px;
  }
`
)

const RemoveBtn = styled.div(
  ({ theme: { colors } }) => `
  background:${colors.inputBackground};
  display:block;
  cursor:pointer;
  width:35px;
  height:35px;
  display:flex;
  justify-content:center;
  align-items:center;

  border-radius:50%;
  svg{
    width:15px;
    height:15px;
    color:${colors.primary};
  }
  &:hover{
    background:${colors.primary};

    svg{ fill:${colors.inputBackground}}
  }
`
)

const HeadingAdditional = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const wrapInput = (
  name: keyof SalesFormData | keyof SalesUser,
  element: React.FC<unknown>,
  // tslint:disable-next-line: no-any
  props: any,
  flex: number | string
) => {
  const labelComp = (
    <FormLabel>
      {props.label} {props.required && <Required>*</Required>}
    </FormLabel>
  )

  return (
    <div className="elem" style={{ flex }}>
      {props.labelDir !== 'after' && labelComp}
      {React.createElement(element, { ...props })}
      {props.labelDir === 'after' && labelComp}
    </div>
  )
}

const SalesFormContent = styled.div(({ theme: { grid } }) => ({
  padding: '60px 0',
}))
const FormField = styled.div(({ theme: { grid } }) => ({
  marginBottom: '60px',
}))

const MobileBreak = styled.div(
  ({ theme: { grid } }) => `
  flex-basis: 100%;
    height: 20px;
  ${mq[2]}{
    flex-basis: 0;
    height:0;
  }
`
)

const DesktopBreak = styled.div(
  ({ theme: { grid } }) => `
  flex-basis: 100%;
    height: 20px;
 `
)

export const FormRow = styled.div(({ theme: { grid } }) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
  alignItems: 'center',
  marginBottom: '30px',
  input: { width: '100%' },
  '.elem': {
    [mq[2]]: {
      margin: '0 5px',
    },
    '&:first-of-type': { marginLeft: 0 },
    '&:last-children': { marginRight: 0 },
  },
  button: { marginLeft: '10px' },
}))
const FormLabel = styled.span(
  ({ theme: { colors } }) => `
font-size:1rem;
display:inline-block;
margin-bottom:5px;
&.error{
  padding:5px 5px 0px 5px;
  margin:-5px -5px 0px -5px;
  background:${rgba(colors.error, 0.2)};
}
a{
  color:${colors.primary};

}
`
)
const Required = styled.span(
  ({ theme: { colors } }) => `
color:${colors.primary};
`
)

import styled from '@emotion/styled'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { SalesService } from '../api'
import { mq } from './../utility/breakpoint'

import { PageContainer } from '../components/organisms/pageContainer'
import { PageContainerOuter } from '../components/organisms/pageContainerOuter'

import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import {
  SalesForm,
  SalesFormData,
  SALESFORM_ERROR,
  SALESFORM_SENT,
} from '../components/organisms/salesForm'

const SalesformPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const [loaded, setLoaded] = React.useState(false)
  const [salesFormStatus, setSalesFormStatus] = React.useState<
    string | undefined
  >()

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const sendForm = data => {
    SalesService.sendForm(data).then(({ status }) => {
      setSalesFormStatus(status !== 204 ? SALESFORM_ERROR : SALESFORM_SENT)
    })
  }

  return (
    <PageFrame {...params} hideMenu={true} pageName="index">
      <PageContainerOuter>
        <PageContainer>
          {loaded && (
            <SalesForm
              status={salesFormStatus}
              onReset={() => setSalesFormStatus('')}
              onSubmit={data => sendForm(data)}
            />
          )}
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default SalesformPage

const KeyVisual = styled.div(() => ({
  height: '320px',
  background: 'url(/assets/keyvisual.jpg) center center / cover',
}))

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { TimeUtil } from 'cuenect-web-core'
import enGb from 'date-fns/locale/en-GB'
import dayjs from 'dayjs'
import React from 'react'
import Picker, { registerLocale } from 'react-datepicker'
import { Input } from '../../atoms'
registerLocale('en-gb', enGb)
import { pickerUI } from './ui/datePickerUi'

registerLocale('en-gb', enGb)

interface DatePickerProps {
  name: string
  onChange(date: Date): {}
  filterDate?(date: Date): void
  dateFormat?: string
  label?: string
  value?: dayjs.Dayjs
  range?: dayjs.Dayjs[] | null
  showDate?: boolean
  showTime?: boolean
  minDate?: dayjs.Dayjs | null
  maxDate?: dayjs.Dayjs | null
  dateRanges?: [[dayjs.Dayjs, dayjs.Dayjs]] | null
}

/* export const Input = React.forwardRef<
  InputProps,
  JSX.IntrinsicElements['input']
>(({ ...rest }, ref) => { */

export const DatePicker: React.FC<DatePickerProps> = React.forwardRef<
  DatePickerProps,
  JSX.IntrinsicElements['input']
>(
  (
    {
      name,
      onChange,
      dateFormat = 'dd-MM-y',
      value = TimeUtil.getNow(),
      label = name,
      showDate = true,
      showTime = true,
      minDate = null,
      maxDate = null,
      range = null,
      dateRanges = null,
      filterDate,
    },
    ref
  ) => {
    const pickerRef = React.useRef()
    const [currentValue, setCurrentValue] = React.useState(value)
    const [format, setFormat] = React.useState<string>(
      `${showDate ? 'L' : ''} ${showTime ? 'LT' : ''}`.trim()
    )
    const theme = useTheme()

    React.useEffect(() => {
      setCurrentValue(value)
    }, [value])

    const utcDate = new Date(currentValue.format())

    let extraProps = {}
    if (dateRanges) {
      extraProps = {
        ...extraProps,
        includeDates: dateRanges.map(d => d.toDate()),
      }
    }

    if (filterDate) {
      extraProps = {
        ...extraProps,
        filterDate,
      }
    }

    return (
      <PickerContainer>
        <PickerElement className={pickerUI(theme)}>
          <Picker
            customInput={<Input label={label} name={name} ref={ref} />}
            ref={pickerRef}
            showTimeSelect={showTime}
            showTimeSelectOnly={!showDate && showTime}
            timeIntervals={15}
            timeFormat={'HH:mm'}
            dateFormat={dateFormat}
            selected={utcDate}
            locale="en-gb"
            onChange={date => {
              setCurrentValue(TimeUtil.get(date))
              onChange(TimeUtil.get(date))
            }}
            minDate={minDate ? new Date(minDate.format()) : null}
            maxDate={maxDate ? new Date(maxDate.format()) : null}
            {...extraProps}
            /*  minTime={range ? new Date(range[0].toDate()) : undefined}
            maxTime={range ? new Date(range[1].toDate()) : undefined} */
          />
        </PickerElement>
      </PickerContainer>
    )
  }
)
const PickerContainer = styled.div(
  ({ theme: { colors } }) => `
`
)

const PickerElement = styled.div(
  ({ theme: { colors } }) => `

`
)
